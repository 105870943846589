export const requestStatus = {
    'loading': 'loading',
    'success': 'success',
    'error': 'error',
    'logout': 'logout',
    'wait_logout': 'wait_logout',
};

export const block_type = {
    messages: 'messages',
    action: 'action',
    button_action: 'button_action',
    split: 'split',
    delay: 'delay',
    condition: 'condition',
};

export const action_type = {
    create_order: 'create_order',
    add_to_cart: 'add_to_cart',
};

export const action_value = {
    create_order: 'Create order',
    add_to_cart: 'Add to cart'
};

export const block_type_color = {
    messages: 'primary',
    action: 'warning',
    button_action: 'warning',
    split: 'success',
    delay: 'danger',
    condition: 'dark',
};
export const message_type = {
    text: 'text',
    image: 'image',
    typing: 'typing',
    wait_input: 'wait_input',
    card: 'card',
    product_card: 'product_card',
    quick_reply: 'quick_reply',
    cart: 'cart',
    order: 'order',
};

export const button_type = {
    web_url: 'web_url',
    postback: 'postback',
    checkout: 'checkout',
    action: 'action',
};
export const quick_reply_type = {
    text: 'Text',
    user_phone_number: 'User phone',
    user_email: 'User email',
};
/*
export const button_subtype = {
    message: 'message'
};*/

export const provider = {
    shopify: 'shopify',
    facebook: 'facebook'
};

export const ui_action_type = {
    CREATE_BLOCK: 'CREATE_BLOCK',
    CREATE_BLOCK_BY_BUTTON: 'CREATE_BLOCK_BY_BUTTON',
    OPEN_BUTTON_SETTINGS: 'OPEN_BUTTON_SETTINGS',
    DISABLE_PANEL_OVERFLOW: 'DISABLE_PANEL_OVERFLOW',
    OPEN_POPUP: 'OPEN_POPUP',
    CLOSE_POPUP: 'CLOSE_POPUP',
    CHANGE_BLOCK_SIZE: 'CHANGE_BLOCK_SIZE',
    INIT_SOURCE: 'INIT_SOURCE',
    NEW_CONNECTION: 'NEW_CONNECTION',
    UPDATE_Z_INDEX: 'UPDATE_Z_INDEX',
};

export const unit_value = {
    m: 'minute',
    h: 'hour',
    d: 'day'
};

export const widgets = {
    optin_manual: {
        slug: 'optin_manual',
        name: 'Custom Subscribe Widget'
    },
    add_cart_popup: {
        slug: 'add_cart_popup',
        name: 'Add To Cart Popup'
    },
    sticky_discount: {
        slug: 'sticky_discount',
        name: 'Sticky Discount Button'
    },
    discount: {
        slug: 'discount',
        name: 'Discount For Subscription'
    },
    order_page: {
        slug: 'order_page',
        name: 'Thank You Page Widget'
    },
    chat: {
        slug: 'chat',
        name: 'Custom Support Chat'
    },
    optin: {
        slug: 'optin',
        name: 'Subscribe Widget'
    }
};
export function urlBuilder() {
    return  Array.prototype.join.call(arguments, '/')
}


export function debounce(func, wait, immediate) {
    let timeout;
    return () => {
        let context = this, args = arguments;
        let later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function findObjectIndexById(id, array, field){
    let index = -1;
    let items = array.filter(item => `${item[field]}` === `${id}`);
    if(items.length){
        index = array.indexOf(items[0]);
    }
    return index;
}

export function imgURL(src, size) {
    return src ? src
            .replace(/_(pico|icon|thumb|small|compact|medium|large|grande|original|1024x1024|2048x2048|master)+\./g, '.')
            .replace(/\.jpg|\.png|\.gif|\.jpeg/g, function(match) {
                return '_'+size+match;
            })
        : '/images/default.png'
        ;
}


export function buildFilterString(query){
    let result = '';
    for (let key in query) {
        result += query[key]!== undefined ? `&${key}=${query[key]}` : ''
    }
    return result.substring(1, result.length);
}


export function buildFilterIdsString(query){
    let result = '';
    for (let i=0; i<query.length; i++) {
        result += `&ids=${query[i]}`
    }
    return result.substring(1, result.length);
}

export const checkParent = (child, parent) => {
    return parent.contains(child)
};

export const replaceMessage = (text) => {
    text = text.replace(/{{/g, '<span class="btn btn-label-primary fb-placeholder-text" contentEditable="false">' );
    text = text.replace(/}}/g, '</span>' );
    return text;
};

export const replaceHtml = (code) => {
    code = code.replace(/<span class="btn btn-label-primary fb-placeholder-text" contenteditable="false">/g, '{{');
    code = code.replace(/<\/span>/g, '}}');
    code = code.replace(/&nbsp;/g, '');
    code = code.replace(/<div>/g, '');
    code = code.replace(/<\/div>/g, '');
    return code;
};

export const setFilterForBuilder = (params) =>{
    let filter = [];
    params.forEach(item =>{
        let data = {
            name: item.slug,
            field_type: item.type,
            label: item.name
        };
        if(item.options){
            data.options = item.options;
        }
        filter.push(data)
    });
    return filter;
};

const regexp_block_title = {
    messages: /(messages #)+[0-9]+/,
    action: /(action #)+[0-9]+/,
    split: /(split #)+[0-9]+/,
    delay: /(delay #)+[0-9]+/,
    condition: /(condition #)+[0-9]+/,
    button_action: /(button action #)+[0-9]+/
};
export const findIndex = (string, field) =>{
    let index = null;
    let test_string = `${string}`;
    let is_title_correct = regexp_block_title[field].test(test_string);
    // console.log(string,  is_title_correct, regexp_block_title[field], regexp_block_title[field].test(test_string));
    if(is_title_correct){
        // console.log(string);
        index = +`${string}`.substr(field.length+2, string.length);
    }

    return index;
};