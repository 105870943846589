import * as TYPES from "./types";

export default {
    [TYPES.RECEIVE](state: any, payload: any) {
        state.shop = {...payload};
    },
    [TYPES.RECEIVE_SETTINGS](state: any, payload: any) {
        state.settings = {...payload};
    },
    [TYPES.SET_TARIFF_REDIRECT](state: any, payload: any) {
        state.tariffs_redirect_url = payload;
    },
    [TYPES.SET_STATUS](state: any, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field,
        };
    }
};
