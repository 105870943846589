import mutations from "./mutations";
import {ILayoutState} from "@/store/modules/layout/interfaces";
export * from "./types";
import packageJson from '../../../../package.json';

const state: ILayoutState = {
    projectName: packageJson.name,
    is_open_aside: false
};

export default {
    state,
    mutations
};
