import Vue from "vue";
import Vuex from "vuex";
import camelCase from "lodash/camelCase"; 

// Получаем все файлы
const requireModule = require.context(
    // Ищем файлы в текущей директории
    "./modules",
    // Ищем файлы в поддиректориях
    true,
    // Исключаем файл index.js, равно как и файлы, в именах которых
    // есть строки 'actions', 'mutations', или 'getters' .
    // Кроме того, включаем только файлы с расширением .js
    /^(?!.*(actions|mutations|getters|index|types|api|interfaces)).*\.ts$/
);

const modules: any = {
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
};

requireModule.keys().forEach(fileName => {
  // Игнорируем файлы модульных тестов
  if (/\.unit\.ts$/.test(fileName)) return;
  // Избавляемся от расширений файлов для преобразования их имён к верблюжьему стилю
  const key : string = camelCase(fileName.split("/")[1].replace(/(\.\/|\.ts)/g, ""));
  modules[key] = {
    namespaced: true,
    ...requireModule(fileName).default
  };
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== "production"
});

export default store;