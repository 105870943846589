import Http from "@/services/http";
import { service_url } from "@/services/config";

export function login(data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/auth/login/`,
        method: "post",
        data: { ...data.data }
    });
}

export function setToken(data:any) {
    return Http._axios({
        url: `${service_url}/api/v1/auth/token/`,
        method: 'post',
        data: data.data
    })
}

export function logout() {
    return Http._axios({
        url: `${service_url}/api/v1/auth/logout/`,
        method: "post"
    });
}
