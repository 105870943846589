import actions from "./actions";
import mutations from "./mutations";
export * from "./types";

const state = {
    status: { change_field: null },
    list: [],
    discount: {}
};

export default {
    state,
    actions,
    mutations,
};
