import * as TYPES from "./types";

export default {
    [TYPES.SET_AUTH](state: any, payload: any) {
        state.redirect_url = payload.redirect_url;
    },
    [TYPES.CHANGE_AUTH](state: any, payload: boolean) {
        state.isAuthenticated = payload;
    },
    [TYPES.SET_STATUS](state: any, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field,
        };
    }
};
