import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
    errors: [],
    user: {},
    redirect_url: "",
    isAuthenticated: !!JSON.parse(localStorage.getItem("user") || 'null'),
    status: { change_field: null },
};

export default {
    state,
    actions,
    mutations,
    getters
};
