import axios from 'axios';
import {requestStatus} from './index';
import {url} from './config';

class Http {
  static _axios(config) {
    if (!config || !config.url) {
      throw Object.assign({
        message: 'Http request error: config required'
      });
    }

    config.method = config.method || 'get';

    if (config.url && config.url.indexOf('http') === -1 && config.url.indexOf('https') === -1 && config.url[0] !== '/')
      config.url = '/' + config.url;

    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
      axios.defaults.headers.common['Authorization'] = `Token ${user.token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }

    document.tempCookie = document.cookie;
    document.cookie = '';


    return axios(config).then((response) => {
      return {response: response, status: requestStatus.success};
    }).catch((error) => {
      if (error.response && error.response.status && (+error.response.status === 401 || +error.response.status === 403)) { //unauthorization
        if (!user || !user.token) {
          localStorage.removeItem('user');
        }
        window.location = `${url}/login`;
      }

      let response_error = {status: requestStatus.error, code: +error.response.status};
      if (error.response && error.response.data && error.response.status) {
        let error_msg = error.response.data ?
          error.response.data.detail ? error.response.data.detail :
            error.response.data.non_field_errors ? error.response.data.non_field_errors[0] : '' : '';
        switch (+error.response.status) {
          case 405:
            response_error = {status: requestStatus.error, messages: error_msg};
            break;
          case 400:
            response_error = {
              status: requestStatus.error,
              messages: typeof error_msg === 'string' && error_msg.length ? error_msg : 'Bad request'
            };
            break;
          case 500:
            response_error = {
              status: requestStatus.error,
              messages: typeof error_msg === 'string' && error_msg.length ? error_msg : 'Server Error. Try again later.'
            };
            break;
          default:
            response_error = {status: requestStatus.error};
        }
      }
      return response_error
    })
      .finally(() =>{
        document.cookie = document.tempCookie;
        document.tempCookie = null;
      })
  }
}


export default Http;