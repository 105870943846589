import Vue from 'vue'
import VueRouter from 'vue-router'
import vSelect from "vue-select";
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import store from './store/store'
import router from './routes'
import Toaster from 'v-toaster'


Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(Toaster, {timeout: 5000});


vSelect.props.components.default = () => ({
    OpenIndicator: {
        render: (createElement: any) =>
            createElement("span", { class: { "la-angle-down": true, la: true } })
    },
    Deselect: {
        render: (createElement: any) =>
            createElement("span", { class: { "la-times": true, la: true } })
    }
});

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
});
