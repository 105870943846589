import * as TYPES from "./types";
import * as API from "./api";
import {requestStatus} from "@/services/services";

export default {
    [TYPES.LOGIN]({commit}: any, credentials: any) {
        let loaderStatus = requestStatus.loading;
        commit(TYPES.SET_STATUS, {
            status: loaderStatus,
            field: "login"
        });
        API.login(credentials)
            .then(({response, status}: any) => {
                loaderStatus = status;
                if (status === requestStatus.success) {
                    commit(TYPES.SET_AUTH, response.data);
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: loaderStatus === requestStatus.loading ? requestStatus.error : loaderStatus,
                    field: "login"
                });
            });
    },

    [TYPES.SET_TOKEN]({commit}: any, data: any) {
        let loaderStatus = requestStatus.loading;
        commit(TYPES.SET_STATUS, {
            status: loaderStatus,
            field: "login"
        });
        API.setToken(data)
            .then(
                ({response, status}: any) => {
                    if (response.data.token) {
                        loaderStatus = status;
                        localStorage.setItem('user', JSON.stringify(response.data));
                        commit(TYPES.CHANGE_AUTH, true);
                    }
                }
            )
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: loaderStatus === requestStatus.loading ? requestStatus.error : loaderStatus,
                    field: "login"
                });
            });
    },

    [TYPES.LOGOUT](context: any) {
        let loaderStatus = requestStatus.loading;

        if (context.state.isAuthenticated) {
            context.commit(TYPES.SET_STATUS, {
                status: loaderStatus,
                field: "logout"
            });
            API.logout().then(
                ({status}: any) => {
                    loaderStatus = status;
                }
            )
                .finally(() => {
                    context.commit(TYPES.SET_STATUS, {
                        status: loaderStatus === requestStatus.loading ? requestStatus.error : loaderStatus,
                        field: "logout"
                    });
                });
        }
    },
}