import * as TYPES from "./types";
import * as API from "./api";
import {requestStatus} from "@/services/services";

export default {
    [TYPES.LOAD]({commit}: any) {
        let status = requestStatus.loading;
        let field = "load";
        commit(TYPES.SET_STATUS, {status, field});
        
        API.loadTariffs()
            .then(({response, status: loaderStatus}: any) => {
                status = loaderStatus;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE, response.data);
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },
    [TYPES.CHECK_CODE]({commit}: any, data: string) {
        let status = requestStatus.loading;
        let field = "check_code";
        commit(TYPES.SET_STATUS, {status, field});

        API.checkCode(data)
            .then(({response, status: loaderStatus}: any) => {
                status = loaderStatus;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_DISCOUNT, response.data);
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },

}