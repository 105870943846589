<template>
  <div>
    <router-view></router-view>
    <tooltip-component></tooltip-component>
  </div>

</template>

<script>
  import TooltipComponent from './components/TooltipComponent'

  export default {
    name: 'app',
    components: {TooltipComponent},
  }
</script>
<style scoped>
  @import "./assets/style/style.bundle.css";
  @import "./assets/fonts/font-awesome/css/all.css";
  /*@import "./assets/vendors/line-awesome/css/line-awesome.css";*/
</style>

<style lang="scss">
  @import "./assets/style/theme-extension";
  @import "./assets/style/base";
  @import "./assets/style/selected";
</style>