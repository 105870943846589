import * as TYPES from "./types";

export default {
    [TYPES.RECEIVE](state: any, payload: any) {
        state.list = [...payload.results];
    },
    [TYPES.RECEIVE_DISCOUNT](state: any, payload: any) {
        state.discount = {...payload};
    },
    [TYPES.SET_STATUS](state: any, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field,
        };
    }
};
